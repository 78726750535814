<template>
  <div class="api-page">
    <iframe :src="swaggerUrl" width="100%" height="100%" frameborder="0" scrolling="auto" />
  </div>
</template>

  <script>
  import { swaggerUrl } from '@/api/finder';

  export default {
    name: 'Swagger',
    data () {
      return {
        swaggerUrl
      };
    }
  };
  </script>

  <style lang="scss" scoped>
  .api-page {
    width: 100%;
    height: 100%;
  }
  </style>
